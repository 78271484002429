<!-- @format -->

<template>
  <div class="flex justify-center">
    <div
      class="bg-white shadow-md w-full rounded-md"
      :class="isPreview ? ' medical-letter-container ' : ''"
      ref="contentToPrint"
    >
      <div :class="isPreview ? 'page ' : ''">
        <div v-if="isPreview" class="flex justify-between">
          <img src="../../../assets/icons/border_left.svg" alt="image-1" />
          <img src="../../../assets/icons/border_right.svg" alt="image-2" />
        </div>
        <div class="px-10 py-6">
          <div
            class="flex justify-between items-center border-b-4 border-hrBlue pb-4"
          >
            <h1 class="text-black text-3xl font-normal">
              <span class="font-semibold">{{
                medicalLetter?.medical_letter?.name
              }}</span>
            </h1>
            <img
              src="../../../assets/icons/health_report_logo.svg"
              alt="Health Report Logo"
            />
          </div>
          <div class="flex gap-4">
            <div class="w-2/3">
              <div class="font-bold text-gray-800 mt-8 mb-4">
                Clinic Information
              </div>
              <table class="w-full text-left mb-5">
                <tr>
                  <th class="w-1/2 text-black font-light text-xs align-top">
                    Clinic Name:
                  </th>
                  <td class="text-hrBlue text-xs">
                    {{ doctorProfile?.practice?.practice_name }}
                  </td>
                </tr>
                <tr class="">
                  <th class="text-black font-light text-xs align-top">
                    Address:
                  </th>
                  <td class="text-hrBlue text-xs">
                    {{ doctorProfile?.practice?.address }}
                  </td>
                </tr>
              </table>

              <div class="font-bold text-gray-800 mt-4 mb-4">
                Doctor Information
              </div>
              <table class="w-full text-left mb-5">
                <tr class="">
                  <th class="w-1/2 text-black font-light text-xs align-top">
                    Doctor's Name:
                  </th>
                  <td class="text-hrBlue text-xs">
                    <span class="capitalize flex justify-between ml-1.5">
                      Dr.
                      {{
                        doctorProfile.first_name + " " + doctorProfile.last_name
                      }}
                    </span>
                  </td>
                </tr>
                <tr class="">
                  <th class="text-black font-light text-xs align-top">
                    GMC Registration:
                  </th>
                  <td class="text-hrBlue text-xs">
                    <span class="capitalize flex justify-between ml-1.5">
                      {{ doctorProfile.gmc_number }}
                    </span>
                  </td>
                </tr>
              </table>
            </div>
            <div
              class="text-gray-800 mb-4 mt-8 w-1/2"
              :class="isPreview ? '' : ''"
            >
              <span class="font-bold">Personal Information</span>

              <table class="w-full text-left mt-3 mb-4">
                <tr>
                  <th class="w-1/2 text-black font-light text-xs align-top">
                    Name:
                  </th>
                  <td class="text-hrBlue text-xs capitalize">
                    {{ medicalLetter?.full_name }}
                  </td>
                </tr>
                <tr>
                  <th class="w-1/2 text-black font-light text-xs align-top">
                    Sex:
                  </th>
                  <td class="text-hrBlue text-xs capitalize">
                    {{ medicalLetter?.gender }}
                  </td>
                </tr>
                <tr>
                  <th class="w-1/2 text-black font-light text-xs align-top">
                    Date of Birth:
                  </th>
                  <td class="text-hrBlue text-xs">
                    {{ moment(medicalLetter?.dob)?.format("DD/MM/YYYY") }}
                  </td>
                </tr>
              </table>

              <table class="w-full text-left mb-5">
                <tr>
                  <th class="w-1/2 text-black font-light text-xs align-top">
                    Date Submitted:
                  </th>

                  <td class="text-hrBlue text-xs">
                    {{
                      moment(medicalLetter?.created_at)?.format("DD/MM/YYYY")
                    }}
                  </td>
                </tr>
                <tr>
                  <th class="w-1/2 text-black font-light text-xs align-top">
                    Date Validated:
                  </th>
                  <td class="text-hrBlue text-xs">
                    {{ moment()?.format("DD/MM/YYYY") }}
                  </td>
                </tr>
              </table>
            </div>
          </div>
          <div class="flex flex-col justify-between">
            <div class="leading-7 text-sm">
              <p>To Whom It May Concern:</p>
              <div v-if="!isPreview" @click="enableQuill">
                <QuillEditor
                  ref="quillEditor"
                  style="min-height: 350px"
                  class="border-hrBlue rounded-b-lg border-opacity-20 border-2 border-top-0 letter-body"
                  max="1"
                  toolbar="minimal"
                  content-type="text"
                  @update:content="emitFieldChange"
                  v-model:content="letterBody"
                  @text-change="handleTextChange"
                  :key="medicalLetter"
                  :options="{
                    theme: 'snow',
                    bounds: '.letter-body',
                    modules: {
                      clipboard: {
                        matchVisual: false,
                      },
                      keyboard: {
                        bindings: {},
                      },
                    },
                  }"
                />
                <div v-if="limitExceed && !isPreview" class="text-red-500">
                  Your content exceeds the maximum allowed limit of two pages.
                  Please shorten your text to proceed.
                </div>
              </div>

              <div v-else>
                <div class="h-97">
                  <p class="leading-6" v-html="page1Content"></p>
                  <p
                    class="leading-5 mt-2 text-sm"
                    v-if="(isA4Exceed && !isPreview) || !isA4Exceed"
                  >
                    Should you require further details regarding this
                    certificate or the patient’s condition, please do not
                    hesitate to contact me using the information provided above.
                  </p>
                </div>
              </div>
            </div>
            <p class="leading-5 mt-2 text-sm" v-if="!isPreview">
              Should you require further details regarding this certificate or
              the patient’s condition, please do not hesitate to contact me
              using the information provided above.
            </p>

            <div class="">
              <div class="">
                <p
                  v-if="(isA4Exceed && !isPreview) || !isA4Exceed"
                  class="mt-10"
                >
                  Yours sincerely,
                </p>
              </div>
              <div
                v-if="isSignatureLoading"
                class="flex items-center justify-center w-1/6"
              >
                <div
                  class="animate-spin rounded-full h-10 w-8 border-t-4 border-hrBlue border-b-4"
                ></div>
              </div>
              <div class="" v-else>
                <div
                  v-if="!isA4Exceed || (isA4Exceed && !isPreview)"
                  class="text-center h-full flex w-full my-1 justify-between"
                >
                  <div>
                    <div
                      v-if="doctorProfile?.doctor_signature"
                      class="signature-image-wrapper"
                    >
                      <img
                        :src="doctorProfile?.doctor_signature"
                        class="w-44"
                      />
                    </div>
                    <div v-else class="font-cursive mt-2 capitalize">
                      {{
                        doctorProfile?.first_name +
                        " " +
                        doctorProfile?.last_name?.charAt(0).toUpperCase() +
                        "."
                      }}
                    </div>
                    <p class="border-t-2 border-black pt-1 capitalize">
                      {{
                        doctorProfile?.first_name +
                        " " +
                        doctorProfile?.last_name?.charAt(0).toUpperCase() +
                        "."
                      }}
                    </p>
                  </div>
                  <div
                    class="text-center h-8"
                    :class="doctorProfile?.signature ? 'mt-8' : 'mt-7'"
                  >
                    <p class="mb-1">
                      {{ moment(date)?.format("DD MMMM YYYY") }}
                    </p>
                    <div class="border-t-2 border-black px-28"></div>
                    <p class="pt-1 font-bold">Date</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="isA4Exceed && isPreview">
        <div
          class="text-center text-sm font-light text-white border-t border-hrBlue bg-hrBlue p-5"
          v-if="isPreview"
        >
          <div class="mb-1.5">
            <a
              href="mailto:info@healthreports.co.uk"
              class="text-white no-underline"
              >info@healthreports.co.uk</a
            >
            |
            <a
              href="https://dev.healthreports.co.uk/"
              class="text-white no-underline"
              >dev.healthreports.co.uk</a
            >
          </div>
        </div>
        <div class="w-full" v-if="!isGeneratingPdf"></div>
        <div :class="isPreview ? 'page' : ''">
          <div class="flex justify-between">
            <img src="../../../assets/icons/border_left.svg" alt="image-1" />
            <img src="../../../assets/icons/border_right.svg" alt="image-2" />
          </div>
          <div class="px-10 pt-3 pb-4">
            <div
              class="flex justify-between items-center border-b-4 border-hrBlue pb-4"
            >
              <h1 class="text-black text-3xl font-normal">
                <span class="font-semibold">{{
                  medicalLetter?.medical_letter?.name
                }}</span>
              </h1>
              <img
                src="../../../assets/icons/health_report_logo.svg"
                alt="Health Report Logo"
              />
            </div>
          </div>

          <div class="px-10 pb-10">
            <div v-if="isA4Exceed && isPreview" class="mt-5 pl-5 text-sm">
              <p v-html="page2Content"></p>
            </div>
            <p class="leading-5 text-sm mt-2">
              Should you require further details regarding this certificate or
              the patient’s condition, please do not hesitate to contact me
              using the information provided above.
            </p>
            <p class="mt-4 leading-7 text-sm">Yours sincerely,</p>
            <div class="flex justify-between items-center mt-4">
              <div class="text-center h-full">
                <div
                  v-if="doctorProfile.doctor_signature"
                  class="signature-image-wrapper"
                >
                  <img :src="doctorProfile.doctor_signature" class="w-44" />
                </div>
                <div v-else class="font-cursive mt-4 w-44 capitalize">
                  {{
                    doctorProfile?.first_name +
                    " " +
                    doctorProfile?.last_name?.charAt(0).toUpperCase() +
                    "."
                  }}
                </div>
                <p class="border-t-2 border-black pt-1 capitalize">
                  {{
                    doctorProfile?.first_name +
                    "  " +
                    doctorProfile?.last_name?.charAt(0).toUpperCase() +
                    "."
                  }}
                </p>
              </div>
              <div class="text-center h-8">
                <p class="mb-1">{{ moment(date)?.format("DD MMMM YYYY") }}</p>
                <div class="border-t-2 border-black px-28"></div>
                <p class="pt-1 font-bold">Date</p>
              </div>
            </div>
          </div>
        </div>
        <div
          class="text-center text-sm font-light text-white border-t border-hrBlue bg-hrBlue p-5"
          v-if="isPreview && isA4Exceed"
        >
          <div class="mb-1">
            <a
              href="mailto:info@healthreports.co.uk"
              class="text-white no-underline"
              >info@healthreports.co.uk</a
            >
            |
            <a
              href="https://dev.healthreports.co.uk/"
              class="text-white no-underline"
              >dev.healthreports.co.uk</a
            >
          </div>
        </div>
      </div>

      <div
        class="text-center text-sm font-light text-white border-t border-hrBlue bg-hrBlue p-5 w-full"
        v-if="isPreview && !isA4Exceed"
      >
        <div class="mb-1">
          <a
            href="mailto:info@healthreports.co.uk"
            class="text-white no-underline"
            >info@healthreports.co.uk</a
          >
          |
          <a
            href="https://dev.healthreports.co.uk/"
            class="text-white no-underline"
            >dev.healthreports.co.uk</a
          >
        </div>
      </div>
      <div
        v-if="showModal"
        class="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 transition-opacity duration-300 ease-in-out"
        :class="{ 'opacity-100': showModal, 'opacity-0': !showModal }"
      >
        <div
          class="bg-white w-1/2 shadow-lg rounded-lg transform transition-transform duration-300 ease-in-out py-6"
        >
          <div
            class="flex justify-between items-center border-teal border-b mx-4 pb-3"
          >
            <div class="">
              <h2 class="text-4xl font-medium text-teal tracking-tight">
                Select
              </h2>
            </div>
            <div class="border-t">
              <button
                @click="closeModal"
                class="w-full py-0.5 px-1.5 rounded-full bg-red-500 font-bold text-white text-base hover:bg-red-600 transition-colors material-icons"
              >
                close
              </button>
            </div>
          </div>
          <div class="bg-white max-h-520 overflow-auto rounded-lg">
            <div class="accordion w-full max-w-2xl mx-auto">
              <div
                v-for="(items, category) in selectedCertificate"
                :key="category"
                class="accordion-item border-b"
              >
                <div
                  class="accordion-header flex justify-between items-center p-4 cursor-pointer"
                  @click="toggleAccordion(category)"
                >
                  <h3 class="text-gray-700">{{ category }}</h3>
                  <span
                    class="text-gray-500 font-extralight transform transition-transform duration-300 w-5"
                    :class="{ 'rotate-90': activeCategory === category }"
                  >
                    >
                  </span>
                </div>

                <Transition name="accordion" @enter="onEnter" @leave="onLeave">
                  <div
                    v-show="activeCategory === category"
                    class="accordion-body bg-teal-light bg-opacity-5 pl-10"
                  >
                    <div>
                      <div v-if="Array.isArray(items)">
                        <div
                          v-for="(item, index) in items"
                          :key="index"
                          class="text-black mb-2 hover:text-teal hover:bg-teal-light hover:bg-opacity-5 cursor-pointer transition-colors duration-300"
                          @click="insertSubItem(item)"
                        >
                          {{ item }}
                        </div>
                      </div>
                      <div
                        v-else
                        v-for="(subItems, subCategory) in items"
                        :key="subCategory"
                      >
                        <strong class="block text-black"
                          >{{ subCategory }}:</strong
                        >
                        <div class="ml-4">
                          <div
                            v-for="(subItem, index) in subItems"
                            :key="index"
                            class="text-black cursor-pointer hover:text-teal hover:bg-teal-light hover:bg-opacity-5 transition-colors duration-300"
                            @click="insertSubItem(subItem)"
                          >
                            {{ subItem }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Transition>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, toRefs, defineEmits, watch } from "vue";
import { defineProps } from "vue";
import moment from "moment";
import { getCertificateContent } from "../../../dataObject/medicalCertificates/medicalCertificates";
import { useMedicalData } from "../../../composables/useMedicalData";
import { QuillEditor } from "@vueup/vue-quill";
import "@vueup/vue-quill/dist/vue-quill.snow.css";

const { getRelevantData } = useMedicalData();
const props = defineProps({
  isPreview: {
    type: Boolean,
  },
  signature: {
    type: String,
  },
  medicalLetter: {
    type: Object,
  },
  isSignatureLoading: {
    type: Boolean,
  },
  doctorProfile: {
    type: Array,
  },
  isGeneratingPdf: {
    type: Boolean,
  },
});
const enableQuill = () => {
  if (limitExceed.value == true) {
    const quill = quillEditor.value?.getQuill();
    quill.enable(true);
  }
};
const emit = defineEmits([
  "field-changed",
  "on-doctor-selection",
  "on-max-limit-reached",
]);
const { isPreview, medicalLetter, doctorProfile, isGeneratingPdf } =
  toRefs(props);
const date = ref(new Date());
const isA4Exceed = ref(false);

const quillEditor = ref("");
const page1Content = ref();
const letterBody = ref();
const replacePatientName = (text, userName) => {
  return text?.replace(/\[Patient’s Full Name\]/g, userName);
};
const page2Content = ref("");
const showModal = ref(false);
const selectedCertificate = ref(null);

const activeCategory = ref(null);

const cursorPosition = ref(null);
const cursorPositionEnd = ref(null);
const matchedSubstring = ref("");
watch(
  medicalLetter,
  () => {
    const letterContent = getCertificateContent(
      medicalLetter.value?.medical_letter?.name
    );
    const formattedName = `${
      medicalLetter.value?.full_name?.[0]?.toUpperCase() || ""
    }${medicalLetter.value?.full_name?.slice(1) || ""}`;

    letterBody.value = replacePatientName(letterContent, formattedName);
  },
  { immediate: true }
);

const toggleAccordion = (category) => {
  activeCategory.value = activeCategory.value === category ? null : category;
};
const totalContentHieght = ref(null);
const limitExceed = ref(false);
const checkHeight = () => {
  const letterContent = document.querySelector(".letter-body");
  const a4HeightInPixels = 380;
  const a4SecondPageHieght = 540;
  if (letterContent) {
    const totalHeight = letterContent.offsetHeight;

    if (totalHeight > a4HeightInPixels) {
      totalContentHieght.value = totalHeight;
      isA4Exceed.value = true;
      const content = letterContent.innerHTML;
      const splitIndex = Math.floor(
        content?.length * (a4HeightInPixels / totalHeight)
      );
      page1Content.value = content.slice(0, splitIndex + 200);
      page2Content.value = content.slice(splitIndex + 200);

      if (totalContentHieght.value - a4HeightInPixels > a4SecondPageHieght) {
        const quill = quillEditor.value?.getQuill();
        quill.enable(false);
        limitExceed.value = true;
        emit("on-max-limit-reached", true);
      } else {
        limitExceed.value = false;
        emit("on-max-limit-reached", false);
      }
    } else {
      isA4Exceed.value = false;
      page1Content.value = letterContent.innerHTML;
      page2Content.value = "";
    }
  }
};
watch(
  letterBody,
  () => {
    checkHeight();
  },
  {
    immediate: true,
  }
);
watch(
  page2Content,
  () => {
    if (
      page2Content.value?.includes("<br>") &&
      page2Content.value?.length <= 13 &&
      isA4Exceed.value
    ) {
      isA4Exceed.value = false;
    }
  },
  { immediate: true }
);

const emitFieldChange = (value, fieldName) => {
  if (fieldName) emit("field-changed", { field: fieldName, value });
  else emit("field-changed", { field: "letterBody", value });
};

const handleTextChange = () => {
  const quill = quillEditor.value.getQuill();
  const content = quill
    .getContents()
    .ops.map((op) => op.insert)
    .join("");

  const relevantData = getRelevantData(content);
  if (relevantData) {
    cursorPosition.value = quill.getSelection();
    cursorPositionEnd.value = relevantData?.length;
    matchedSubstring.value = relevantData?.substring;

    getCertificateContent(medicalLetter.value?.medical_letter?.name);
    selectedCertificate.value = relevantData?.data;

    showModal.value = true;
  }
};

const closeModal = () => {
  showModal.value = false;
};

const insertSubItem = (subItem) => {
  const quill = quillEditor.value.getQuill();

  const editorContent = quill.getText();

  const matchedIndex = editorContent.indexOf(matchedSubstring.value);

  if (matchedIndex !== -1) {
    quill.deleteText(matchedIndex, matchedSubstring.value?.length);

    quill.insertText(matchedIndex, subItem);

    showModal.value = false;
  }
};

const onEnter = (el) => {
  el.style.height = "auto";
  const height = el.scrollHeight;
  el.style.height = "0";
  // Force browser reflow
  el.offsetHeight;
  el.style.height = height + "px";
};

const onLeave = (el) => {
  el.style.height = el.scrollHeight + "px";
  // Force browser reflow
  el.offsetHeight;
  el.style.height = "0";
};
</script>

<style scoped>
.font-cursive {
  font-family: "Brush Script MT", cursive;
  font-size: xx-large;
}

.page {
  min-height: 1065px;
  height: 100%;
  position: relative;
}
img {
  max-height: 60px;
}

.ql-editor {
  font-size: 14px;
  line-height: 1.5;
}

.page {
  height: 250mm; /* A4 height */
  background-color: white;

  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}
.ql-tooltip,
:deep(.ql-tooltip) {
  visibility: hidden !important;
  display: none !important;
  opacity: 0 !important;
  pointer-events: none !important;
}

.ql-editor + .ql-tooltip {
  display: none !important;
}

.accordion-enter-active,
.accordion-leave-active {
  transition: height 0.3s ease-in-out, opacity 0.3s ease-in-out;
  overflow: hidden;
}

.accordion-enter-from,
.accordion-leave-to {
  height: 0;
  opacity: 0;
}

::v-deep .vs__dropdown-toggle {
  padding: 2px;
  background-color: white;
  cursor: pointer !important;
}
::v-deep ul li:not(:hover) {
  background-color: white !important;
  color: black;
}
::v-deep ul li:hover {
  background-color: #1d4b68 !important;
  color: white;
}

::v-deep .vs__selected-options {
  flex-wrap: nowrap;
  max-width: calc(100% - 40px);
}

::v-deep .vs__selected {
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 95%;
  overflow: hidden;
}
::v-deep ul li {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.medical-letter-container {
  min-width: 800px;
  max-width: 100%;
  margin: 0 auto;

  min-height: 1065px;
}

@media print {
  .medical-letter-container {
    width: 800px !important;
    height: 1065px !important;
    margin: 0 !important;
    padding: 0 !important;
    page-break-after: always;
  }

  .page {
    height: 1065px !important;
    page-break-after: always;
  }
}
</style>
