/** @format */

import allergyData from "./allergyCertificate";
import blueBadgeData from "./blueBadgeCertificate";
import chickenpoxRecoveryData from "./chickenpoxRecovery";
import covid19RecoveryData from "./covid_19Recovery";
import covid19TestVerificationData from "./covid_19TestVerification";
import disabilityMedicalCertificateData from "./disabilityMedicalCertificate";
import eventAndActivityCancellationData from "./eventAndActivityCancellation";
import fitToTravelCruiseData from "./fitToTravelCruise";
import fitToWorkData from "./fitToWork";
import goodHealthCertificateData from "./goodHealthCertificate";
import gpReferralData from "./gpReferral";
import gymAndHealthClubCancellationData from "./gymAndHealthClubCancellation";
import housingSupportData from "./housingSupport";
import injuryAndAccidentData from "./injuryAndAccident";
import medicalExemptionCertificateData from "./medicalExemptionCertificate";
import pregnancyFitToFlyData from "./pregnancyFitToFly";
import selfCertificationData from "./selfCertification";
import travelAndHolidayCancellationData from "./travelAndHolidayCancellation";
import travelWithMedicationData from "./travelWithMedication";
import universityAndCollegeSicknessData from "./universityAndCollegeSickness";
import vaccineExemptionCertificateData from "./vaccineExemptionCertificate";
import workSicknessCertificateData from "./workSicknessCertificate";

const certificates = {
  allergyData,
  blueBadgeData,
  chickenpoxRecoveryData,
  covid19RecoveryData,
  covid19TestVerificationData,
  disabilityMedicalCertificateData,
  eventAndActivityCancellationData,
  fitToTravelCruiseData,
  fitToWorkData,
  goodHealthCertificateData,
  gpReferralData,
  gymAndHealthClubCancellationData,
  housingSupportData,
  injuryAndAccidentData,
  medicalExemptionCertificateData,
  pregnancyFitToFlyData,
  selfCertificationData,
  travelAndHolidayCancellationData,
  travelWithMedicationData,
  universityAndCollegeSicknessData,
  vaccineExemptionCertificateData,
  workSicknessCertificateData,
};

const certificateNameMapping = {
  "Allergy Certificate": "allergyData",
  "Blue Badge Medical Letter": "blueBadgeData",
  "Disability Medical Letter": "disabilityMedicalCertificateData",
  "GP Referral Letter": "gpReferralData",
  "Fit to Work": "fitToWorkData",
  "Gym & Health Club Cancellation": "gymAndHealthClubCancellationData",
  "Vaccine Exemption Certificate": "vaccineExemptionCertificateData",
  "Medical Exemption Certificate": "medicalExemptionCertificateData",
  "Housing Support Medical Letter": "housingSupportData",
  "Chickenpox Recovery": "chickenpoxRecoveryData",
  "Covid-19 Recovery Certificate": "covid19RecoveryData",
  "Covid-19 Test Verification": "covid19TestVerificationData",
  "University & College Sickness": "universityAndCollegeSicknessData",
  "Rapid COVID-19 Test Result Verification": "covid19TestVerificationData",
  "Work Sickness Certificate": "workSicknessCertificateData",
  "Event Cancellation Certificate": "eventAndActivityCancellationData",
  "Injury & Accident Confirmation Letter": "injuryAndAccidentData",
  "Holiday Cancellation": "travelAndHolidayCancellationData",
  "Travel Medication": "travelWithMedicationData",
  "Housing Support Letter": "housingSupportData",
  "Private sick-note self certification": "selfCertificationData",
  "GP Sickness Note": "gpReferralData",
  "Fit to travel-air": "fitToTravelCruiseData",
  "Fit to Travel - Cruise": "fitToTravelCruiseData",
  "Certificate of Good Health": "goodHealthCertificateData",
  "Pregnancy Fit to Fly": "pregnancyFitToFlyData",
};

export const getCertificateContent = (name) => {
  const mappedName = certificateNameMapping[name] || name;

  return certificates[mappedName]?.content?.data || null;
};

export default certificates;
