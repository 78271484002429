<!-- @format -->

<template>
  <div>
    <div class="flex gap-4 justify-center">
      <div style="width: 800px">
        <div v-if="isLoading" class="bg-white">
          <LetterSkeletonLoader />
        </div>
        <MedicalLetterTemplate
          v-else
          :isPreview="isPreview"
          :signature="signature"
          :medicalLetter="medicalLetter?.data"
          :doctorProfile="doctorProfile"
          :isSignatureLoading="loadingDoctor"
          :isGeneratingPdf="isGeneratingPdf"
          @field-changed="handleFieldChange"
          @on-doctor-selection="handleDoctorSelection"
          @on-max-limit-reached="handleMaxLimitReached"
          :key="doctorProfile"
        />
        <div class="flex mt-4" v-if="isPreview">
          <button
            class="font-medium w-full p-3 rounded-md mr-2"
            @click="isPreview = false"
            :class="
              isGeneratingPdf
                ? 'border border-gray-700 text-gray-700 cursor-not-allowed'
                : 'border border-teal text-teal '
            "
            :disabled="isGeneratingPdf"
          >
            Back
          </button>
          <button
            class="bg-teal text-white w-full font-medium p-3 rounded-md flex justify-center items-center gap-4"
            :style="{ pointerEvents: isGeneratingPdf ? 'none' : 'auto' }"
            @click="sendCertificate"
            :disabled="isGeneratingPdf"
          >
            Send Certificate
            <span v-if="isGeneratingPdf"> <RingLoader /></span>
          </button>
        </div>
      </div>
      <!-- <div class="w-2/6">
        <div class="w-full" v-if="!isPreview">
          <DoctorProfile
            v-if="selectedDoctor"
            :doctor="selectedDoctor"
            :key="selectedDoctor"
            :loading="loadingDoctor"
          />
          <div
            v-else
            class="bg-gray-500 bg-opacity-10 border border-gray-400 border-dashed h-20 flex justify-center items-center text-gray-700 rounded-lg"
          >
            No Profile Selected
          </div>
        </div>
        <div>
          <textarea
            v-model="note"
            placeholder="Write your note here..."
            class="w-full mt-4 p-2 border border-gray-300 rounded"
          ></textarea>
        </div>
      </div> -->
    </div>
    <div class="flex mt-4" v-if="!isPreview">
      <button
        class="bg-teal text-white w-full font-medium p-3 rounded-md"
        :class="isFormChanged ? '' : ' bg-opacity-60 cursor-not-allowed'"
        :disabled="!isFormChanged"
        @click="navigate(1)"
      >
        Save & Preview
      </button>
    </div>
  </div>
</template>

<script setup>
import { ref, watch, onMounted, computed } from "vue";
import MedicalLetterTemplate from "../../../components/newUi/MedicalLetters/MedicalLetterTemplate.vue";
// import DoctorProfile from "../../../components/newUi/MedicalLetters/DoctorProfile.vue";
import LetterSkeletonLoader from "@/components/newUi/atoms/LetterSkeletonLoader.vue";
import RingLoader from "@/components/newUi/atoms/RingLoader.vue";
import SuccessIcon from "@/assets/icons/success.svg";
import errorIcon from "@/assets/icons/error.svg";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import Swal from "sweetalert2";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";

const store = useStore();
const route = useRoute();
const router = useRouter();
const isPreview = ref(false);
const signature = ref("");
const isFormChanged = ref(false);
const letterId = route.params.letterId;
const signatureLoader = ref(false);

const isLoading = ref(true);
const maxLimit = ref(false);
const isGeneratingPdf = ref(false);
const selectedDoctor = ref("");

const loadingDoctor = ref(false);
const letter = ref({
  letterBody: "",
  doctor: "",
});

const note = ref("");

const validate = (letterElements) => {
  let hasError = false;
  if (!letterElements.letterBody || letterElements?.letterBody?.length < 20)
    hasError = true;

  if (maxLimit.value) hasError = true;

  return !hasError;
};

const handleMaxLimitReached = (flag) => {
  maxLimit.value = flag;
};

watch(
  [letter, signature, maxLimit],
  () => {
    isFormChanged.value = validate(letter.value);
  },
  { deep: true }
);

const handleFieldChange = (payload) => {
  letter.value[payload.field] = payload.value;
};

const handleDoctorSelection = (doctor) => {
  loadingDoctor.value = true;
  selectedDoctor.value = doctor;
  setTimeout(() => {
    loadingDoctor.value = false;
  }, 500);
};

const navigate = () => {
  isPreview.value = true;
};
const medicalLetter = computed(() => {
  return store.getters["PMLModule/getMedicalLetterRequest"];
});
const doctorProfile = computed(() => {
  return store.getters["PMLModule/getDoctor"];
});

const generatePDFWithJSPDF = async () => {
  isGeneratingPdf.value = true;
  const element = document.querySelector(".medical-letter-container");

  // Create a clone for PDF generation
  const clone = element.cloneNode(true);
  const container = document.createElement("div");
  container.style.position = "absolute";
  container.style.left = "-9999px";
  container.style.top = "0";
  container.style.width = "800px";
  container.appendChild(clone);
  document.body.appendChild(container);

  try {
    // Wait for images to load
    const images = Array.from(clone.getElementsByTagName("img"));
    await Promise.all(
      images.map((img) => {
        return new Promise((resolve) => {
          if (img.complete) {
            resolve();
          } else {
            img.onload = resolve;
            img.onerror = resolve;
          }
        });
      })
    );

    // Handle signature image
    const signatureImg = clone.querySelector(".signature-image-wrapper");
    if (signatureImg) {
      await new Promise((resolve) => {
        const img = new Image();
        img.crossOrigin = "anonymous";
        img.onload = () => {
          signatureImg.src = img.src;
          resolve();
        };
        img.onerror = resolve;
        img.src = signatureImg.src;
      });
    }

    // Generate canvas
    const canvas = await html2canvas(clone, {
      useCORS: true,
      allowTaint: true,
      logging: false,
      imageTimeout: 15000,
      scale: 2,
      width: 800,
      windowWidth: 800,
      onclone: (clonedDoc) => {
        const clonedElement = clonedDoc.querySelector(
          ".medical-letter-container"
        );
        if (clonedElement) {
          clonedElement.style.width = "800px";
          clonedElement.style.margin = "0";
          clonedElement.style.transform = "none";
        }
      },
    });

    const imgData = canvas.toDataURL("image/png");
    const pdf = new jsPDF("p", "mm", "a4");

    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = pdf.internal.pageSize.getHeight();

    const imgWidth = pdfWidth;
    const imgHeight = (canvas.height * pdfWidth) / canvas.width;

    let heightLeft = imgHeight;
    let position = 0;

    // First page
    pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
    heightLeft -= pdfHeight;

    // Add pages if content exceeds first page
    while (heightLeft >= 0) {
      position = heightLeft - imgHeight;
      pdf.addPage();
      pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
      heightLeft -= pdfHeight;
    }

    const pdfBlob = pdf.output("blob");
    const pdfFile = new File([pdfBlob], "medical_letter.pdf", {
      type: "application/pdf",
    });
    const formData = new FormData();
    // Rest of your confirmation and sending logic
    const { name: letterName } =
      medicalLetter.value?.data?.medical_letter || {};
    const { full_name: name } = medicalLetter.value?.data || {};
    const pdfDataURILocal = pdf.output("datauristring");

    const confirmationResult = await Swal.fire({
      width: "22%",
      padding: "1%",
      html: `
        Are you sure you want to send <b>${letterName}</b> to <b>${name}</b>?
      `,
      showCancelButton: true,
      cancelButtonText: "No",
      confirmButtonText: "Yes",
      reverseButtons: true,
      confirmButtonColor: "rgba(0, 164, 153, 1)",
      allowOutsideClick: false,
      customClass: {
        title: "medical-letter-title",
        popup: "border-radius",
        content: "custom-text",
        icon: "icon-border",
        cancelButton: "medical-letter-no-button",
        confirmButton: "medical-letter-yes-button",
      },
    });

    if (confirmationResult.isConfirmed) {
      pdfDataURI.value = pdfDataURILocal;
      // pdf.save("medical_letter.pdf");

      formData.append("medical_letter", pdfFile);
      formData.append("medical_letter_request", letterId);
      formData.append("doctors_note", note.value);

      try {
        await store
          .dispatch("PMLModule/sendMedicalLetter", formData)
          .then(() => {
            isGeneratingPdf.value = false;
            Swal.fire({
              title: `${letterName} Sent to ${name} via Email`,
              iconHtml: `<img src="${SuccessIcon}" />`,
              width: "32em",
              confirmButtonText: "Done",
              confirmButtonColor: "rgba(0, 164, 153, 1)",
              customClass: {
                title: "popup-title",
                popup: "border-radius",
                content: "custom-text",
                icon: "icon-border",
                confirmButton: "medical-letter-yes-button",
              },
              didClose: () => {
                router.push({ name: "hq-medicalLettersDashboard" });
              },
              showConfirmButton: true,
            });
          });
      } catch (error) {
        Swal.fire({
          title: "Failed to send the medical letter",
          text: "An error occurred while sending the letter. Please try again.",
          iconHtml: ` <img src="${errorIcon}" />`,
          confirmButtonText: "OK",
          confirmButtonColor: "rgba(255, 77, 77, 1)",
          customClass: {
            title: "popup-title",
            popup: "border-radius",
            content: "custom-text",
            icon: "icon-border",
            confirmButton: "medical-letter-yes-button",
          },
        });
        isGeneratingPdf.value = false;
      }
    } else {
      isPreview.value = false;
      isGeneratingPdf.value = false;
    }
  } catch (error) {
    Swal.fire({
      title: "PDF Generation Failed",
      text: "An error occurred while generating the PDF. Please try again.",
      iconHtml: ` <img src="${errorIcon}" />`,
      confirmButtonText: "OK",
      confirmButtonColor: "rgba(255, 77, 77, 1)",
    });
  } finally {
    // Clean up
    if (container && container.parentNode) {
      container.parentNode.removeChild(container);
    }
    isGeneratingPdf.value = false;
  }
};

const pdfDataURI = ref(null);
const sendCertificate = () => {
  generatePDFWithJSPDF();
};

onMounted(async () => {
  await store
    .dispatch("PMLModule/fetchMedicalLetterRequest", {
      letterId: letterId,
    })
    .then(() => {
      isLoading.value = false;
    });
  signatureLoader.value = true;
  await store.dispatch("PMLModule/fetchSignature").then(() => {
    signatureLoader.value = false;
  });
  await store.dispatch("PMLModule/fetchDoctor");
});
</script>
